<template>
  <div class="requisites-page">
    <div class="container">
      <div class="requisites-page__wrapper">
        <div class="head">
          <h1>Реквизиты</h1>
        </div>
        <div class="body" ref="pageContent"><!--
          <div><strong>Полное наименование:</strong> Общество с ограниченной ответственностью «Ваш Сервис»&nbsp;</div>
          <div><br><strong>Краткое наименование:</strong> ООО «Ваш Сервис»<br><br><strong>Директор:</strong> Краюшкин
            Дмитрий Алексеевич, действующий на основании Устава <br><br><strong>ОГРН:</strong>
            1152468017250<br><br><strong>ИНН:</strong> 2460088826<br><br><strong>КПП:</strong> 246501001<br><br><strong>Юридический
              адрес:</strong> 660022, Россия, Красноярский край, г.Красноярск, ул. Партизана Железняка, д. 7
            пом.13<br><br></div>
          <div><strong>Сайт:</strong> <a href="https://sgleague.pro">sgleague.pro</a></div>
          <div><br></div>
          <pre><br></pre>
          <div><br></div>
        -->
          <div class="subtitle">Информация о сетевом издании:</div>
          <ol class="list">
            <li><strong>Наименование (название) издания:</strong> SGL</li>
            <li><strong>Учредитель (соучредители):</strong> ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ВАШ СЕРВИС" (ОГРН
              1152468017250)
            </li>
            <li><strong>Главный редактор:</strong> Краюшкин Дмитрий Алексеевич</li>
            <li><strong>Электронная почта редакции:</strong> <a href="mailto:venomic@bk.ru">venomic@bk.ru</a></li>
            <li><strong>Телефон редакции:</strong> <a href="tel:+79135321249">+7 (913) 532 1249</a></li>
            <li><strong>Знак информационной продукции:</strong> 16+</li>
            <li><strong>Зарегистрировавший орган: </strong> Федеральная служба по надзору в сфере связи, информационных
              технологий и массовых коммуникаций (Роскомнадзор), регистрационный номер — ЭЛ № ФС 77-85250 от 27.04.2023
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "requisites",
  data() {
    return {

    }
  },
}
</script>

<style scoped lang="scss">
.subtitle {
  font-size: 1.5em;
  margin-bottom: 1.3rem;
}

ol.list > li {
  list-style-type: decimal;
  margin-bottom: 1rem;
  font-size: 1.2em;
}
</style>